.today-status-container {
    background: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .today-status-heading {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
    color: #22263D;
    
  }
  
  .today-status-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .status-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin: 8px;
    min-width: 100px;
  }
  
  .status-label {
    text-transform: capitalize;
  }
  
  .status-value {
    font-size: 18px;
    font-weight: bold;
  }
  
  .accepted { color: #F29423; }
  .cancelled { color: #FF6347; }
  .expired { color: #A27B1E; }
  .failed { color: #BF1722; }
  .pending { color: #1677A3; }
  .rfq-accepted { color: #00C6D8; }
  .rfq-expired { color: #9C73DF; }
  .rfq-rejected { color: #232020; }
  .settled { color: #FF3D91; }
  

.today-status-inner-container {
    display: flex;
    justify-content: start;
    gap: 3rem;
    margin-left: 0.5rem 0;
    font-weight: 500;

    /* font-size: 1.05rem; */
}

.BSE-color {
    color: #23376C;
}

.NSE-color {
    color: #E22028;
}

.warning-capsule {

    color: #F29423;
}
.success-capsule {

    color: #039F39;
}

.failed-capsule {
    color: #FF0000;
}

.ord-title {
    color: #AF862E;
}


.today-status-color-1 {
    color: #F4C354;
}

.today-status-color-2 {
    color: #1EE0B4;
}

.today-status-color-3 {
    color: #AF862E;
}

.today-status-color-4 {
    color: #E85347;
}

.today-status-color-5 {
    color: #8091A7;
}

.today-status-color-6 {
    color: #0CC3DF;
}

.today-status-color-7 {
    color: #B7C2D0;
}