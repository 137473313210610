.bond-detail-container {
    font-family: Arial, sans-serif;
    max-width: 100%;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.order-detail-header {
    text-align: center;
    margin-bottom: 20px;
}

/* Switch container */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }
  
  /* Hide default checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* Slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px; /* Rounded corners */
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%; /* Rounded knob */
  }
  
  /* Toggle on (checked) */
  input:checked + .slider {
    background-color: #a27b1e; /* Blue background */
  }
  
  input:checked + .slider:before {
    transform: translateX(20px); /* Move the knob */
  }
  

.order-detail-inner-con {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    width: 100%;
}

.order-detail-img {
   border-radius: 100%;
   border: 1px solid #d2d2d2;
   height: 5rem;
   width: 5rem;
}

.order-detail-buy-color {
  color: #90929E;
}
.order-detail-cust-info {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .order-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;  /* Size of the circle */
    height: 40px;  /* Size of the circle */
    background-color: #A27B1E;  /* Circle background color */
    border-radius: 50%;  /* Makes the div a circle */
    color: white;  /* Text color */
    font-size: 20px;  /* Font size for the initial character */
    font-weight: bold;  /* Bold text */
  }
  
  h4 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }

.editable-field {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.editable-field label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
}

.editable-field input,
.editable-field select,
.editable-field textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.error-text {
    color: red;
    font-size: 12px;
}

.save-button {
    padding: 10px 20px;
    background-color: #A27B1E;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.save-button:hover {
    background-color: #906e1d;
}